figure {
  margin: 40px 0;
  position: relative;

  img {
    width: 100%;
    width: 65vw;

    @media (min-width: 950px) {
      width: calc(100% + 70px);
    }
  }
}

figcaption,
.caption {
  font-weight: $semibold;
  font-size: 10px;
  color: #9b9b9b;
  width: 100%;
  display: block;
  margin-top: 20px;
  line-height: 140%;

  @media (min-width: 685px) {
    position: absolute;
    left: -160px;
    bottom: 0;
    margin-top: 0;
    width: 140px;
  }

  &.bottom {
    left: 0;
    top: calc(100% + 20px);
  }

  &.bottom-right {
    left: unset;
    right: 0;
    top: calc(100% + 20px);
  }
}
