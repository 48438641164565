.page-footer {
  background-color: $black;
  color: $white;
  border-top: 1px solid $white;
  display: flex;
  padding: 45px;
  flex-wrap: wrap;

  &__col {
    width: 100%;
    max-width: 100%;
    margin-bottom: 35px;

    &:last-child {
      max-width: 100%;

      @media (min-width: 924px) {
        flex: 1;
      }
    }

    @media (min-width: 480px) {
      width: 50%;
    }

    @media (min-width: 750px) {
      width: 25%;
      max-width: 100%;
    }

    @media (min-width: 924px) {
      width: 100%;
      max-width: 230px;
      margin-bottom: 0;
    }
  }

  p {
    font-size: 10px;
    line-height: normal;
  }

  a {
    text-decoration: none;
    font-weight: $bold;
  }
}
