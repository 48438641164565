html {
  font-size: 62.5%;
}

body {
  font-family: $sans-serif;
  font-size: 1.5em; // currently ems cause chrome bug misinterpreting rems on body element
  font-weight: $regular;
  line-height: 1.6;
}

hr {
  border: 0;
  border-top: 1px solid #ccc;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}
