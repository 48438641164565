$valencia: #d24436;
$earls-green: #b9ca3c;
$tradewind: #56b5a0;
$black: #030303;
$dusty-gray: #9b9b9b;
$white: #fefefe;

$primary-color: $valencia;
$secondary-color: $earls-green;
$tertiary-color: $tradewind;

$sans-serif: 'Avenir Next', Frutiger, 'Frutiger Linotype', Univers, Calibri,
  'Gill Sans', 'Gill Sans MT', 'Myriad Pro', Myriad, 'DejaVu Sans Condensed',
  'Liberation Sans', 'Nimbus Sans L', Tahoma, Geneva, 'Helvetica Neue',
  Helvetica, Arial, sans-serif;
$serif: Cambria, 'Hoefler Text', Utopia, 'Liberation Serif',
  'Nimbus Roman No9 L Regular', Times, 'Times New Roman', serif;
$monospace: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
  'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
  'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;

$thin: 100;
$extralight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$verybold: 900; // Black
