.mc-field-group {
  display: flex;

  label {
    flex: 1;
  }
}

.email#mce-EMAIL {
  width: 100%;
}
