.row {
  display: flex;
  position: relative;
  margin: 0 0 65px;
  justify-content: center;
  width: 100%; // overflow: hidden;
  flex-direction: column;
  overflow: hidden;

  @media (min-width: 685px) {
    flex-direction: row;
  }

  &--overflow-visible {
    overflow: visible;
  }

  .col {
    width: 100%;
    margin: 0;

    &:first-child {
      @media (min-width: 685px) {
        max-width: 220px;
        margin-right: 100px;
        flex: 1 1 220px;
      }
    }

    &:last-child {
      @media (min-width: 685px) {
        max-width: 590px;
        flex: 1 1 590px;
      }
    }
  }

  &--reverse {
    .col {
      &:first-child {
        @media (min-width: 685px) {
          max-width: 590px;
          margin-right: 100px;
          flex: 1 1 590px;
        }
      }

      &:last-child {
        @media (min-width: 685px) {
          max-width: 220px;
          flex: 1 1 220px;
        }
      }
    }
  }

  &.two-col {
    overflow: visible;

    .col {
      margin: 0 10px;
      flex: unset;

      @media (min-width: 685px) {
        max-width: 445px;
      }

      img {
        max-width: 100%;
      }
    }

    figcaption {
      margin: 18px 0;

      @media (min-width: 685px) {
        margin: 0;
      }
    }
  }
}

.grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  background-color: #1d1d1d;
  font-weight: $bold;
  width: calc(100% + 40px);
  margin: 0 -20px;

  &__item {
    width: 100%;
    background-color: #ccc;
    color: $white;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    font-weight: $bold;

    @media (min-width: 624px) {
      width: 50%; // width: 33.33%;
    }

    @media (min-width: 860px) {
      width: 33.33%; // width: 33.33%;
    }

    p {
      text-align: center;
      padding: 0 60px;
      font-weight: $bold;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &:before {
      content: '';
      float: left;
      padding-top: 100%;
    }
  }
}

.section {
  position: relative;
  padding: 65px 20px;

  &--generic {
    .row {
      overflow: visible;
    }
  }

  &--intro {
    height: 90vh;
    padding: 0;
    background-image: url('../images/paint-chip-background.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      width: 100%;
      height: 208px;
      background-image: linear-gradient(
        -180deg,
        #000000 0%,
        rgba(0, 0, 0, 0) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      content: '';
      z-index: 0;
      opacity: 0.5;
    }

    h1 {
      color: $white;
      font-weight: 600;
      color: #ffffff;
      letter-spacing: 5px;
      text-align: center;
      text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
      max-width: 900px;
      margin: 0 auto;
      padding: 0 34px;
      font-size: 20px;
      line-height: 30px;

      @media (min-width: 480px) {
        font-size: 40px;
        line-height: 50px;
      }

      &:after {
        content: none;
      }
    }

    .caption {
      @media (max-width: 679px) {
        bottom: 40px;
        right: 20px;
        color: #fefefe;
        top: unset;
        position: absolute;
        left: unset;
        width: 120px;
      }
    }
  }

  &--transformation {
    background-color: $black;
    color: $white;

    h1 {
      color: $white;
    }

    a {
      color: inherit;

      &:hover {
        color: $secondary-color;
      }
    }
  }

  &--performing-space {
    padding: 0;
    background-color: $black;

    .text {
      padding: 20px;

      @media (min-width: 900px) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    p {
      color: #fff;

      @media (min-width: 900px) {
        max-width: 300px;
      }
    }
  }

  &--outreach {
    padding-bottom: 0;
  }

  &--ctas {
    background-color: $black;
    font-size: 15px;
    color: $white;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;

    @media (min-width: 745px) {
      flex-direction: row;
      margin-bottom: 0;
    }

    &__col {
      margin: 0 34px 40px;

      @media (min-width: 745px) {
        margin-bottom: 0;
      }

      @media (min-width: 980px) {
        margin: 0 60px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    h3 {
      margin-bottom: 6px;
    }

    p {
      font-size: inherit;
    }
  }
}

.full-bleed {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
