th,
td {
  border-bottom: 1px solid #e1e1e1;
  padding: 12px 15px;
  text-align: left;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}
