ul {
  list-style: circle inside;
}

ol {
  list-style: decimal inside;
}

ol,
ul {
  margin-top: 0;
  padding-left: 0;

  ol,
  ul {
    font-size: 90%;
    margin: 1.5rem 0 1.5rem 3rem;
  }
}

li {
  margin-bottom: 1rem;
}
