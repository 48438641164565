.button,
button,
input[type='submit'],
input[type='reset'],
input[type='button'] {
	background: $earls-green;
	border: none;
	color: $white;
	cursor: pointer;
	font-size: 14px;
	font-weight: $bold;
	letter-spacing: 0;
	padding: 8px 25px;
	text-align: center;
	text-decoration: none;
	width: auto;
	display: inline-block;

	&:hover {
		color: $white;
		background: darken($earls-green, 6%);
	}
}

.button:hover,
button:hover,
input[type='submit']:hover,
input[type='reset']:hover,
input[type='button']:hover,
.button:focus,
button:focus,
input[type='submit']:focus,
input[type='reset']:focus,
input[type='button']:focus {
}

.button.button-primary,
button.button-primary,
input[type='submit'].button-primary,
input[type='reset'].button-primary,
input[type='button'].button-primary {
}

.button.button-primary:hover,
button.button-primary:hover,
input[type='submit'].button-primary:hover,
input[type='reset'].button-primary:hover,
input[type='button'].button-primary:hover,
.button.button-primary:focus,
button.button-primary:focus,
input[type='submit'].button-primary:focus,
input[type='reset'].button-primary:focus,
input[type='button'].button-primary:focus {
}

.button--jump_link {
	background: #b9ca3c;
	font-weight: $semibold;
	font-family: 'Lucida Grande';
	font-size: 14px;
	color: #000000;
	text-align: center;
	width: 45px;
	height: 45px;
	display: block;
	line-height: 45px;
	text-decoration: none;
	z-index: 10;
	margin: auto;
	transform: translateY(50%);
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;

	&:hover {
		color: $white;
		background: darken($earls-green, 6%);
	}
}
