.primary-navigation {
  font-weight: $bold;
  font-size: 14px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row; //     @media (min-width: 625px) {
  //   flex-direction: row;
  // }
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    text-align: right;
    display: flex;

    @media (min-width: 352px) {
      margin: 20px; // @media (min-width: 625px) {
      //   margin: 0;
      // }
    }
  }

  li {
    display: none;

    @media (min-width: 685px) {
      display: block;
    }

    &:last-child {
      display: block;

      a {
        background-color: $earls-green;
        padding: 12px 30px;
        margin-left: 12px;
        color: $white;

        &:hover {
          color: $white;
          background: darken($earls-green, 6%);
        }
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
    padding: 20px;

    &:hover {
      color: $earls-green;
    }
  }

  svg {
    fill: currentColor;
  }

  .home-link {
    color: $white;
  }

  &--dark {
    background-color: rgba(255, 255, 255, 1);
    color: $black;
    border-bottom: 1px solid #e6e6e6;

    a {
      &:hover {
        color: $earls-green;
      }
    }

    .home-link {
      color: $black;
    }
  }
}

.secondary-navigation {
  font-weight: $bold;
  font-size: 11px;
  color: #b9ca3c;
  line-height: 22px;

  @media (min-width: 750px) {
    text-align: right;
  }

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

.sub-navigation {
  list-style: none;
  margin: 0;
  padding: 0;
  font-weight: $bold;
  font-size: 14px;
  padding: 20px 30px;
  border: 2px solid #dfdfdf;
  width: 100%;

  li {
    margin: 12px 0;
    padding: 0;

    &.active {
      a {
        color: $black;
      }
    }
  }

  a {
    text-decoration: none;
  }
}

.social-navigation {
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    display: flex;
    margin: 0 -10px;
    position: relative;
  }

  li {
    margin: 0 10px;

    a {
      @include text-hide;

      display: block;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center center;

      &.facebook {
        background-image: url('../images/facebook-logo.png');
      }
      &.tumblr {
        background-image: url('../images/tumblr-logo.png');
      }
      &.spotify {
        background-image: url('../images/spotify-logo.png');
      }
      &.twitter {
        background-image: url('../images/twitter-logo.png');
      }
      &.instagram {
        background-image: url('../images/instagram-logo.png');
      }
    }
  }
}
