a {
  color: $secondary-color;

  &:hover {
    color: darken($secondary-color, 10%);
  }
}

.back-to-home {
  font-weight: bold;
  font-size: 10px;
  color: #b9ca3c;
  letter-spacing: 2px;
  text-align: left;
  line-height: 31px;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 10px;
  display: block;
}
