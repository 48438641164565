.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.header-title {
  font-weight: $semibold;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 5px;
  text-align: center;
  line-height: 40px;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
}
