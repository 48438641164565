.generic {
  padding-top: 75px;

  @media (min-width: 685px) {
    padding-top: 150px;
  }

  h1 {
    font-weight: bold;
    font-size: 22px;
    color: #000000;
    letter-spacing: 5px;
    margin-top: 20px;

    @media (min-width: 430px) {
      font-size: 34px;
    }

    @media (min-width: 685px) {
      font-size: 54px;
      margin-top: -28px;
    }
  }

  .sub-navigation {
    width: auto;

    @media (min-width: 685px) {
      width: 100%;
    }
  }

  .row {
    .col {
      &:first-child {
        @media (min-width: 685px) {
        }
      }
    }
  }
}
