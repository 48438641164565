code {
  background: #f1f1f1;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  font-size: 90%;
  margin: 0 0.2rem;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}

pre > code {
  display: block;
  padding: 1rem 1.5rem;
  white-space: pre;
}
