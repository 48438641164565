h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
  margin-bottom: 2rem;
  margin-top: 0;
}

h1 {
  font-size: 4rem;
  line-height: 1.2;

  color: #000000;
  display: inline-block;
  font-size: 22px;
  font-weight: $semibold;
  letter-spacing: 5px;
  margin: 0 0 32px;
  position: relative;
  text-transform: uppercase;
  z-index: 0;

  &.multi-line {
    margin-left: 30px;
    margin-top: -28px;

    &::after {
      content: none;
    }
  }

  @media (min-width: 430px) {
    font-size: 34px;
    margin: 0 0 40px;
  }

  @media (min-width: 685px) {
    font-size: 54px;
    margin: 0 0 65px;
  }

  &::after {
    content: '';
    background: #de513f;
    height: 16px;
    width: 500vw;
    position: absolute;
    right: -6px;
    top: 43%;
    z-index: -1;

    @media (min-width: 430px) {
      height: 20px;
    }

    @media (min-width: 685px) {
      height: 40px;
      right: -20px;
    }
  }

  span {
    display: inline-block;
    position: relative;

    &::after {
      content: '';
      background: #de513f;
      height: 16px;
      position: absolute;
      left: -30px;
      right: -30px;
      top: 43%;
      z-index: -1;

      @media (min-width: 430px) {
        height: 20px;
      }

      @media (min-width: 685px) {
        height: 40px;
      }
    }
  }
}

h2 {
  font-size: 3.6rem;
  line-height: 133%;

  font-weight: $semibold;
  font-size: 15px;
  color: #56b5a0;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
}

h3 {
  font-size: 3rem;
  line-height: 1.3;

  font-weight: $semibold;
  font-size: 15px;
  color: #56b5a0;
  letter-spacing: 2px;
  text-align: left;
  text-transform: uppercase;
  line-height: 133%;
}

h4 {
  font-size: 2.4rem;
  line-height: 1.35;
}

h5 {
  font-size: 1.8rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.5rem;
  line-height: 1.6;
}

p {
  margin-top: 0;

  font-weight: $regular;
  font-size: 14px;
  color: inherit;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  orphans: 2;
  widows: 2;
}
